



































































import { Vue, Component } from "vue-property-decorator";
import ComplainFilter from "@/components/UIComponents/Admin/ComplainFilter.vue";
import { IncidentModule, MetaModule, ReportModule } from "@/store/modules";
import { Incident } from "@/store/models/incident";
import incident from "../../store/api/incident";
import { AdminRouter } from "@/utils/routePathContsant";
import pagination from "@/components/UIComponents/Pagination.vue";

@Component({
  components: {
    ComplainFilter,
    pagination,
  },
})
export default class IncidentReportByData extends Vue {
  entries: string = "";
  data: Incident[] = [];
  qData: any[] = [];
  sortOrder: string = "";
  sortProp: string = "";

  filterObj = {
    district: "",
    palika: [],
    incident_category: [],
    date: [],
    status: "",
  };

  async created() {
    MetaModule.loadDis();
    MetaModule.loadComplain();
  }
  get incidents() {
    console.log(ReportModule.complaints);

    return ReportModule.complaints;
  }

  get AdminRouter() {
    return AdminRouter;
  }

  onSortChanged(e: any) {
    this.sortOrder = e.order;
    this.sortProp = e.prop;
    console.log(e);
  }
}
